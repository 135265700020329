import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { DashMain, DashMainContent, DListimg, Loader } from "../bills/billsElements";
import Sidebar from "../bills/Sidebar";
import Modal from "../modal/modal";
// import { useRouter } from 'next/router'
import axios from "axios";
// import Script from 'next/script'
import { isBrowser } from "react-device-detect";
import "../Home/PaymentRequest.css"

import Skeleton from 'react-loading-skeleton'

// import Box from '@mui/material/Box';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import {
  MenuItem,
  Select,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  circularProgressClasses
} from "@mui/material";
import PuffLoader from "react-spinners/PuffLoader"
import PaymentModal from './../modal/paymentType';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { BASE_URL, LIVE_CALL_BACK, TEST_CALL_BACK } from "../../constants/defaultValues";


const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});
NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const PaymentRequest = (props) => {

  const [billServiceId, setBillServiceId] = useState('');
  const [bills, setBills] = useState('');
  const [selectedBiller, setselectedBiller] = useState('');
  const [amount_, setamount] = useState(0);
  const [email, setemail] = useState('');
  const [phone, setphone] = useState('');
  const [name, setname] = useState('');
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [modal, setmodal] = useState(false);
  const [modalMsg, setModalMsg] = useState("not successful");
  const [fixed, setfixed] = useState(false);
  const [active, setactive] = useState(false);
  const [variety, setVariety] = useState(false);
  const [varietyData, setVarietyData] = useState();
  const [type, setType] = useState('');
  const [serviceId, setServiceId] = useState({});

  const [billerCode, setbillerCode] = useState('');

  const [billerCodeValid, setbillerCodevalid] = useState(false);
  const [billerCodeValidMsg, setbillerCodeValidMsg] = useState('');
  const [tvPlan, settvPlan] = useState('');
  const [tvPlanAmount, settvPlanAmount] = useState(0);
  const [validCustomerTvData, setvalidCustomerTvData] = useState(false);
  const [isTvPlanRenew, setisTvPlanRenew] = useState(false);
  const [serviceBiller, setserviceBiller] = useState('');
  const [subType, setsubType] = useState('renew');

  const [billerCodeErr, setbillerCodeErr] = useState(false);
  const [billerCodeErrMsg, setbillerCodeErrMsg] = useState('');

  // eslint-disable-next-line no-unused-vars
  const [transactionId, setTransactionId] = useState('');
  const [proceed, setProceed] = useState(false);

  const [selectedVariety, setSelectedVariety] = useState({});
  const [selectedVarietyFields, setselectedVarietyFields] = useState([]);
  const [selectedVarietyStatus, setselectedVarietyStatus] = useState(false);
  const [selectedVarietyId, setSelectedVarietyId] = useState(null);


  const [productExist, setProductExist] = useState(false);
  const [paymentType, setPaymentType] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [color, setColor] = useState(" #2C63EA");
  const [transactionRefId, setTransactionRefId] = useState(null);
  const [key, setKey] = useState(null);
  const [power, setpower] = useState(false);
  const [powerdata, setpowerdata] = useState(null);
  const [amountErr, SetAmountErr] = useState(false)
  const [amountSuccess, SetAmountSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  let Data;

  const [paymentInfo, setPaymentInfo] = useState({})

  const params = useParams();
  console.log(params)


  useEffect(() => {
    const invoiceRef = params.uniqueRef;
    if (invoiceRef === null || invoiceRef === undefined) {
      // Display error and return to landing page
      window.location = handleCallBack;
    }

    doPayReqProcessing(invoiceRef)


  }, [])




  useEffect(() => {
    if (isBrowser) {
      setProceed(true)
    } else {
      setProceed(false)
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const getValue = async (ref, transId) => {
    const response = await axios.get(`${BASE_URL}/AltBiller/getValue?reqId=${ref}`);
    const data = response.data;
    setpowerdata(data)//

  }

  const handleCallBack = BASE_URL === "https://staging-api.icadpay.com/api" ? TEST_CALL_BACK : LIVE_CALL_BACK;

  const doPayReqProcessing = async (invoiceRef) => {
    try {
      const response = await axios.get(`${BASE_URL}/payment-link?unid=${invoiceRef}`);
      console.log(response);

      const newData = response.data;
      setPaymentInfo(response);
      console.log(newData.currency);
      console.log(paymentInfo);

      let keyValue = newData.publicKye.slice(0, 4);
      const payload = {
        key: keyValue === "test" || newData.publicKye === "live"
          ? newData.publicKye
          : 'live_YzM1ODg3YzY5MWVjZjFlYzhkOTQxMDU3NmMzM2NlYjc4YzQwYTU1M2ZkZjRmNjI5ZjQzOGQzZmM4ZmY3NzZmYQ', // this is not a demo key.  
        email: newData.email, // customer email 
        amount: parseFloat(newData.amount), // amount to be processed
        currency: newData.currency, // currency
        first_name: newData.firstName,
        last_name: newData.lastName,
        phone_number: newData.phone, // customer's phone number (optional)
        customerId: newData.email,
        ref: newData.transactionRef, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
        narration: newData.description,
        isInvoice: true,
        service_bill: billServiceId,
        callback_url: "", // specified redirect URL (optional)
        callback: (response) => {
          try {
            console.log(response);
            axios.post(`${BASE_URL}/Merchant/PostTransactionRef`, {
              transacionRef: response.responseData.merchantTransactionRef,
              id: response.responseData.merchantId
            }).then(resp => {
              console.log(resp);
            });
          } catch (error) {
            console.error("Error in callback:", error);
          }
        },
        onSuccess: (response) => {
          try {
            console.log(response);
            const Data = response;
            axios.post(`${BASE_URL}/Merchant/PostTransactionRef`, {
              transacionRef: Data.responseData.merchantTransactionRef,
              id: Data.responseData.merchantId
            }).then(resp => {
              console.log(resp);
              setTimeout(() => {
                window.location = handleCallBack;
              }, 3000);
            });
          } catch (error) {
            console.error("Error on success:", error);
            alert("An error occurred. Please try again later.");
            window.location = handleCallBack;
          }
        },
        onError: (response) => {
          console.error("Error occurred:", response);
          window.location = handleCallBack;
        },
        onClose: () => {
          try {
            axios.post(`${BASE_URL}/Merchant/PostTransactionRef`, {
              transacionRef: Data.responseData.merchantTransactionRef,
              id: Data.response.responseData.merchantId
            }).then(resp => {
              console.log(resp);
              setTimeout(() => {
                window.location = handleCallBack;
              }, 30000);
            });
          } catch (error) {
            console.error("Error on close:", error);
            alert("An error occurred. Please try again later.");
            window.location = handleCallBack;
          }
        }
      };

      window.IcadPay.setup(payload);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 410) {
          setErrorMessage(error.response.data.message)

        } else {
          console.log("Payment link not found")
        }
      }
    }
  };



  return (
    <div style={{ height:"100vh", display:"flex", alignItems:"center", justifyContent:"center"}}>
      <div >
        {errorMessage && (
          <div className="error-container">
            <div style={{textAlign:"center"}}>
              <img 
                src="/img/failedpayment.jpg"
                alt="sda" 
                width={73}
                height={73}
                className='collectionimg'
              />
             <p style={{fontSize:25, fontWeight:"700"}}> {errorMessage}.</p> 
              <p style={{color:"grey", marginTop:-15}}>Please request a new payment link from merchant or confirm that payment link is correct.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PaymentRequest